import { LOG_OUT } from '../auth/actionTypes';
import {
	CLEAN_REDUCER,
	END_FETCH_MAP_DEVICES,
	FETCH_DEVICE_COMMANDS,
	FETCH_MAP_DEVICES,
	FETCH_SELECTED_DEVICE,
	INIT_FETCH_MAP_DEVICES,
	LAST_MULTIPLE_MAP_DEVICES,
	LAST_SELECTED_DEVICE,
	LAST_TREE_SELECTED_DEVICES,
	TOGGLE_OTHER_UNITS,
	TOGGLE_REFRESH,
	UPDATE_MAP_DEVICES,
} from './actionTypes';

const initState = {
	devices: [],
	deviceCommands: [],
	selectedDevice: 0,
	otherUnits: true,
	refresh: true,
	loadingDevices: false,
	lastSelectedDevice: 0,
	lastSelectTreeDevices: [],
	lastSelectedDevices: [],
	lastMultipleMapDevices: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case INIT_FETCH_MAP_DEVICES:
			return {
				...state,
				selectedDevice: 0,
				loadingDevices: true,
			};
		case END_FETCH_MAP_DEVICES:
			return {
				...state,
				loadingDevices: false,
			};
		case TOGGLE_OTHER_UNITS:
			return {
				...state,
				otherUnits: action.otherUnits,
			};
		case TOGGLE_REFRESH:
			return {
				...state,
				refresh: action.refresh,
			};
		case UPDATE_MAP_DEVICES:
		case FETCH_MAP_DEVICES:
			return {
				...state,
				devices: action.devices,
			};
		case FETCH_DEVICE_COMMANDS:
			return {
				...state,
				deviceCommands: action.deviceCommands,
			};
		case FETCH_SELECTED_DEVICE:
			return {
				...state,
				selectedDevice: action.selectedDevice,
				loadingDevices: false,
			};
		case LAST_SELECTED_DEVICE:
			return {
				...state,
				lastSelectedDevice: action.lastSelectedDevice,
			};
		case LAST_TREE_SELECTED_DEVICES:
			return {
				...state,
				lastSelectTreeDevices: action.lastSelectTreeDevices,
				lastSelectedDevices: action.lastSelectedDevices,
			};
		case LAST_MULTIPLE_MAP_DEVICES:
			return {
				...state,
				lastMultipleMapDevices: action.lastMultipleMapDevices,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
