import dayjs, { ConfigType, Dayjs, ManipulateType, OpUnitType, QUnitType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { DurationUnitsObjectType } from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { locale } from '@/constants/global';
import { UTC_FORMAT, YEAR_MONTH_DAY_TIME_FORMAT } from '@/OptimusRoutes/constants';
import 'dayjs/locale/es';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

type StartAndEndOfDay<T> = { startOfDate: T; endOfDate: T };

type IsSameDay = {
	date: dayjs.ConfigType;
	current: dayjs.ConfigType;
	utcOffsetMinutes?: number;
};

export type DayjsType = Dayjs;

export type dayjsDayjsType = dayjs.Dayjs;

export type ValidateIntervalDates = {
	from: dayjs.ConfigType;
	to: dayjs.ConfigType;
};

export const convertToDate = (stringDate: string | Dayjs): Date => {
	return dayjs(stringDate).toDate();
};

export const stringToDayjs = (dateString: string): dayjs.Dayjs => {
	const converttedDate = dayjs(dateString);
	return converttedDate;
};

export const toDayjs = (date: dayjs.ConfigType, format?: string): dayjs.Dayjs => dayjs(date, format);

export const getFormattedDate = (date?: Date | string, format = 'YYYY-MM-DD'): Date => {
	const getDate = dayjs(date).format(format);
	return dayjs(getDate).toDate();
};

export const formatDate = (format = 'YYYY-MM-DD', normalDate?: Date | string, isUtc = false): string => {
	const date = isUtc ? dayjs.utc(normalDate).local() : dayjs(normalDate);
	const formattedDate = date.format(format);
	return formattedDate;
};

export const getTimeDifference = (
	oldDate: ConfigType,
	newDate: ConfigType = new Date(),
	unit: QUnitType | OpUnitType = 'hour',
	lang: string | ILocale | undefined = 'en'
): number => {
	dayjs.locale(lang);
	const getTime = dayjs(newDate);
	const result = getTime.diff(oldDate, unit);
	return result;
};

export const getCurrentDate = (): dayjs.Dayjs => {
	return dayjs();
};

export const getUtcNow = (): dayjs.Dayjs => {
	return dayjs().utc();
};

export const toUtcDate = (date: string | Date): dayjs.Dayjs => {
	return dayjs(date).utc();
};

export const getUtcStartAndEndOfDate = (
	date: string | Date,
	dateFormat: string,
	unit: dayjs.OpUnitType = 'day'
): StartAndEndOfDay<Date> => {
	const utcDate = typeof date === 'string' ? dayjs.utc(date, dateFormat) : dayjs.utc(date);
	const startOfDay = utcDate.startOf(unit).format(YEAR_MONTH_DAY_TIME_FORMAT);
	const endOfDay = utcDate.endOf(unit).format(YEAR_MONTH_DAY_TIME_FORMAT);

	return { startOfDate: new Date(startOfDay), endOfDate: new Date(endOfDay) };
};

export const utcStartAndEndOfDay = (
	date: string | number | dayjs.Dayjs | Date | null | undefined,
	inputFormat: dayjs.OptionType
): StartAndEndOfDay<string> => {
	const startOfDayUTC = dayjs(date, inputFormat).startOf('day').utc().format(UTC_FORMAT);
	const endOfDayUTC = dayjs(date, inputFormat).endOf('day').utc().format(UTC_FORMAT);

	return { startOfDate: startOfDayUTC, endOfDate: endOfDayUTC };
};

export const getStartOfDay = (date: string): Date => {
	const dateValue = dayjs(date).startOf('day');
	const startOfDayDate = dateValue.toDate();
	return startOfDayDate;
};

export const getEndOfDay = (date: string): Date => {
	const dateValue = dayjs(date).endOf('day');
	const startOfDayDate = dateValue.toDate();
	return startOfDayDate;
};

export const getUtcOffset = (): number => {
	return dayjs().utcOffset();
};

export const utcOffsetMinutesToOffsetHours = (utcOffsetMinutes: number): string => {
	const formattedOffset = dayjs().utcOffset(utcOffsetMinutes).format('Z');
	return formattedOffset;
};

export const getElapsedTimeInUtc = (utcDate: Date): string => dayjs().to(dayjs(utcDate));

export const dateToUtc = (normalDate: Date | string, format = YEAR_MONTH_DAY_TIME_FORMAT): string => {
	const formattedDate = dayjs(normalDate).utc().format(format);
	return formattedDate;
};

export const utcToLocalTimezone = (utcDate: ConfigType, format: string = 'YYYY-MM-DD'): string =>
	dayjs.utc(utcDate).local().format(format);

export const utcToLocalTime = (date: Date, format?: string): dayjs.Dayjs => {
	const localDate = dayjs.utc(date).local();
	const localTimeWithFormat = dayjs(localDate, format);

	return format ? localTimeWithFormat : localDate;
};

export const addUtcoffsetMinutes = (
	date: Date,
	utcOffsetMinutes: number,
	format: string = YEAR_MONTH_DAY_TIME_FORMAT,
	unit: dayjs.ManipulateType | undefined = 'minutes'
): string => dayjs.utc(date).add(utcOffsetMinutes, unit).format(format);

export const subtractUtcoffsetMinutes = (
	date: Date,
	utcOffsetMinutes: number,
	format: string = YEAR_MONTH_DAY_TIME_FORMAT,
	unit: dayjs.ManipulateType | undefined = 'minutes'
): string => dayjs.utc(date).subtract(utcOffsetMinutes, unit).format(format);

export const getDateFromNow = (
	utcDate: string,
	utcOffsetMinutes: string,
	lang: string | ILocale | undefined = 'en'
) => {
	dayjs.locale(lang);
	const formattedDate = dayjs.utc(utcDate).utcOffset(utcOffsetMinutes).fromNow();

	return formattedDate;
};

export const getTime = (): number => dayjs().hour();

export const getNowInMilliseconds = (): number => dayjs().valueOf();

export const dateToString = (
	date: string | number | Date | dayjs.Dayjs | null | undefined,
	newFormat: string | undefined
) => {
	return dayjs(date).format(newFormat);
};

export const subtractDate = (
	qty: number,
	unit: ManipulateType,
	date?: string | number | dayjs.Dayjs | Date | null | undefined,
	format?: string
): string | Dayjs => {
	if (format) {
		return dayjs(date).subtract(qty, unit).format(format);
	}
	return dayjs(date).subtract(qty, unit);
};

export const getDurationMilliSeconds = (durationProps: DurationUnitsObjectType): number => {
	return dayjs.duration(durationProps).asMilliseconds();
};

export const convertSecondDurationToTime = (durationProps: number, format: string = 'H[h] m[m] s[s]'): string => {
	return dayjs.duration(durationProps, 'seconds').format(format);
};

export const isSameDay = ({ date, current, utcOffsetMinutes = 0 }: IsSameDay): boolean => {
	const dateA = dayjs.utc(date).subtract(utcOffsetMinutes, 'minutes').format('YYYY-MM-DD');
	const dateB = dayjs.utc(current).subtract(utcOffsetMinutes, 'minutes').format('YYYY-MM-DD');

	return dayjs.utc(dateA).diff(dayjs.utc(dateB), 'day', true) === 0;
};

export const getWeekdaysTitles = (local: string | ILocale | undefined = locale.en_US.intl) => {
	dayjs.locale(local);
	return dayjs.weekdays();
};

export const validateIntervalDates = (dateIntervals: ValidateIntervalDates[], format: string = 'HH:mm'): boolean => {
	const intervals = dateIntervals.map(({ from, to }) => ({
		from: toDayjs(from, format),
		to: toDayjs(to, format),
	}));

	intervals.sort((a, b) => a.from.valueOf() - b.from.valueOf());

	for (let i = 0; i < intervals.length - 1; i++) {
		if (!intervals[i].to.isSameOrBefore(intervals[i + 1].from)) {
			return false;
		}
	}

	return true;
};

// ! -------- Map Utils -------- !
export const isVehicleMoving = (
	isOn: boolean | null,
	speed: number | null,
	utcOffsetMinutes?: number | null,
	utcDate: Date | string | null = null
) => {
	if (!isOn || !speed) {
		return false;
	}
	const currentUtcOffsetMinutes = utcOffsetMinutes ?? 0;

	const minutes = 3;
	const today = dayjs().utc().utcOffset(currentUtcOffsetMinutes);
	const lastReport = dayjs.utc(utcDate).utcOffset(currentUtcOffsetMinutes);

	const timeDiff = lastReport ? today.diff(lastReport, 'minute') <= minutes : false;

	if (isOn && timeDiff) {
		return true;
	}

	return timeDiff && speed >= 2;
};
