import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { translate } from '@smovilgps/optimus-intl';
import { Col, message, Row } from 'antd';
import { dateToString, formatDate, getUtcStartAndEndOfDate } from '../../../../utilities/dateUtils';
import { DAY_MONTH_YEAR_FORMAT, TASK_STATUS, YEAR_MONTH_DAY_TIME_FORMAT } from '../../../constants';
import { OutdatedTaskAndTotal, OutdatedTasks } from '../../../interfaces';
import { TaskService } from '../../../services';
import PendingTasksDetail from './PendingTasksDetail';

const EMPTY_DATA: OutdatedTaskAndTotal = {
	data: [],
	total: 0,
};

const PendingPastTasks = () => {
	const intl = useIntl();
	const navigate = useNavigate();

	const [unassignedData, setUnassignedData] = useState<OutdatedTaskAndTotal>(EMPTY_DATA);
	const [uncompletedData, setUncompletedData] = useState<OutdatedTaskAndTotal>(EMPTY_DATA);

	const fetchOutdatedTasks = async (status: TASK_STATUS): Promise<OutdatedTaskAndTotal> => {
		try {
			const today = formatDate(DAY_MONTH_YEAR_FORMAT, new Date());
			const { startOfDate: startOfToday } = getUtcStartAndEndOfDate(today, DAY_MONTH_YEAR_FORMAT, 'day');
			const startOfTodayString = dateToString(startOfToday, YEAR_MONTH_DAY_TIME_FORMAT);

			const response = await TaskService.outdatedTasks(startOfTodayString, status);

			return response.success || EMPTY_DATA;
		} catch (e) {
			message.error(intl.formatMessage(translate('commonError')));
			return EMPTY_DATA;
		}
	};

	const formatTaskDate = (data: OutdatedTasks[]) =>
		data.map(({ dueDate, count }) => {
			const formattedDate = formatDate('DD MMM YYYY', dueDate, true);
			return { dueDate: formattedDate, count };
		});

	const setCardsData = async () => {
		const unassignedResponse = await fetchOutdatedTasks(TASK_STATUS.UNASSIGNED);
		const uncompletedResponse = await fetchOutdatedTasks(TASK_STATUS.TO_DO);

		setUnassignedData({
			data: formatTaskDate(unassignedResponse.data),
			total: unassignedResponse.total,
		});
		setUncompletedData({
			data: formatTaskDate(uncompletedResponse.data),
			total: uncompletedResponse.total,
		});
	};

	useEffect(() => {
		setCardsData();
	}, []);

	return (
		<div className="pending-past-tasks">
			<Row gutter={[16, 8]} justify="space-between">
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<PendingTasksDetail
						title={intl.formatMessage(translate('unassignedTasks'))}
						className="unassigned"
						daysList={unassignedData}
						onViewMore={() => navigate(`/optimus-routes/tasks-management?status=unassigned`)}
						messageOnEmpty={intl.formatMessage(translate('noUnassignedTasks'))}
					/>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<PendingTasksDetail
						title={intl.formatMessage(translate('uncompletedTasks'))}
						className="uncompleted"
						daysList={uncompletedData}
						onViewMore={() => navigate(`/optimus-routes/tasks-management?status=uncompleted`)}
						messageOnEmpty={intl.formatMessage(translate('noUnassignedTasks'))}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default PendingPastTasks;
