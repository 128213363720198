import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { ControlOutlined } from '@ant-design/icons';
import { translate } from '@smovilgps/optimus-intl';
import { Col, Row } from 'antd';
import QuickAccessButton from './QuickAccessButton';

const QuickAccess = () => {
	const intl = useIntl();
	const navigate = useNavigate();

	const Icons = {
		TaskPlanner: <span className="icon-sukarneTrips" />,
		TaskManagement: <ControlOutlined />,
	};

	return (
		<div className="quick-access">
			<Row className="overview-title">
				<FormattedMessage {...translate('quickAccess')} />
			</Row>
			<Row gutter={[8, 8]} justify="space-between">
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<QuickAccessButton
						icon={Icons.TaskManagement}
						label={intl.formatMessage(translate('taskManagement'))}
						onClick={() => navigate('/optimus-routes/tasks-management')}
						className="tasks-management"
					/>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<QuickAccessButton
						icon={Icons.TaskPlanner}
						label={intl.formatMessage(translate('taskPlanner'))}
						onClick={() => navigate('/optimus-routes/task-planner')}
						className="task-planner"
					/>
				</Col>
			</Row>
		</div>
	);
};

export default QuickAccess;
