import mqtt from 'mqtt';
import { RABBIT_CONECTED, RABBIT_DISCONECTED } from '@/constants/actionTypes';
import { store } from '@/store/configureStore';
import { ENVIRONMENT } from '../../constants/global';

class MqttSingleton {
	private static instance: MqttSingleton;
	private client: mqtt.MqttClient;
	public connected: boolean = false;
	private subscriptions: { [topic: string]: ((message: string) => void)[] } = {};

	private constructor() {
		const mqttUrl = process.env.REACT_APP_RABBIT_URL || 'ws://localhost:15675';
		const clientId = `web-app.${new Date().getTime()}.${Math.random()
			.toString(16)
			.substring(2, 4)}.${ENVIRONMENT}.`;

		const mqttOptions: mqtt.IClientOptions = {
			clean: true,
			reconnectPeriod: 1000,
			clientId,
			keepalive: 10,
		};

		this.client = mqtt.connect(mqttUrl, mqttOptions);

		this.client.on('reconnect', () => {
			console.log('RABBIT_CONECTING...'); // eslint-disable-line no-console

			this.connected = false;
			store.dispatch({ type: RABBIT_DISCONECTED });
		});

		this.client.on('error', (error) => {
			this.connected = false;
			console.error('MQTT Error:', error); // eslint-disable-line no-console
		});

		this.client.on('connect', () => {
			console.log('RABBIT_CONECTED'); // eslint-disable-line no-console
			for (const topic of Object.keys(this.subscriptions)) {
				this.client.subscribe(topic);
			}
			this.connected = true;
			store.dispatch({ type: RABBIT_CONECTED });
		});

		this.client.on('message', (rawTopic, message) => {
			const topic = rawTopic.replace(/\//g, '.');

			const callbacks = this.subscriptions[topic];
			if (callbacks) {
				for (const callback of callbacks) {
					callback(message.toString());
				}
			}
		});
		this.client.on('close', function () {
			console.log('RABBIT_DISCONECTED'); // eslint-disable-line no-console
		});
	}

	static getInstance(): MqttSingleton {
		if (!MqttSingleton.instance || !MqttSingleton.instance.connected) {
			MqttSingleton.instance = new MqttSingleton();
		}
		return MqttSingleton.instance;
	}
	private cleanSubscriptions(): void {
		if (this.connected) {
			this.subscriptions = {};
		}
	}
	end(): void {
		this.cleanSubscriptions();
	}

	resetConnection(): void {
		this.cleanSubscriptions();
	}

	publish(topic: string, message: string): void {
		if (this.connected) {
			const routingKey = `${topic}.${ENVIRONMENT}`;
			this.client.publish(routingKey, message);
		}
	}

	subscribe(topic: string, callback: (message: string) => void): void {
		if (this.connected) {
			const routingKey = `${topic}.${ENVIRONMENT}`;
			if (!this.subscriptions[routingKey]) {
				this.subscriptions[routingKey] = [];
				this.client.subscribe(routingKey);
			}
			this.subscriptions[routingKey].push(callback);
		}
	}

	unsubscribe(topic: string, callback: (message: string) => void): void {
		if (!callback) {
			throw new Error(
				'A callback is obligatory, if your intentions are to remove all handlers for a routing key you should use unsubscribeAll'
			);
		}
		const routingKey = `${topic}.${ENVIRONMENT}`;
		if (this.subscriptions[routingKey]) {
			const index = this.subscriptions[routingKey].indexOf(callback);
			if (index !== -1) {
				this.subscriptions[routingKey].splice(index, 1);
				if (this.subscriptions[routingKey].length === 0) {
					delete this.subscriptions[routingKey];
					this.client.unsubscribe(routingKey);
				}
			}
		}
	}

	unsubscribeAll(topic: string): void {
		const routingKey = `${topic}.${ENVIRONMENT}`;
		if (this.subscriptions[routingKey]) {
			delete this.subscriptions[routingKey];
			this.client.unsubscribe(routingKey);
		}
	}
}

export default MqttSingleton;
