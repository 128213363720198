import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@smovilgps/optimus-intl';
import { Badge, Col, message, Row } from 'antd';
import {
	convertToDate,
	dateToString,
	DayjsType,
	formatDate,
	getCurrentDate,
	getUtcStartAndEndOfDate,
	stringToDayjs,
} from '../../../../utilities/dateUtils';
import Calendar from '../../../components/dayjs/Calendar';
import {
	COLOR_STATUS_MAP,
	DAY_MONTH_YEAR_FORMAT,
	YEAR_MONTH_DAY_FORMAT,
	YEAR_MONTH_DAY_TIME_FORMAT,
} from '../../../constants';
import { StatusByDate } from '../../../interfaces';
import { TaskService } from '../../../services';
import CalendarList from './CalendarList';

const TasksCalendar = () => {
	const intl = useIntl();

	const [value, setValue] = useState<DayjsType>(getCurrentDate());
	const [statusByDate, setStatusByDate] = useState<StatusByDate[]>();
	const [markedDates, setMarkedDates] = useState<string[]>();

	const fetchCalendarData = async (date: DayjsType) => {
		try {
			const today = formatDate(DAY_MONTH_YEAR_FORMAT, new Date());
			const { startOfDate: startOfToday } = getUtcStartAndEndOfDate(today, DAY_MONTH_YEAR_FORMAT, 'day');

			const selectedDate = convertToDate(date);
			const { startOfDate: startOfMonth, endOfDate: endOfMonth } = getUtcStartAndEndOfDate(
				selectedDate,
				YEAR_MONTH_DAY_TIME_FORMAT,
				'month'
			);

			const startOfMonthString = dateToString(startOfMonth, YEAR_MONTH_DAY_TIME_FORMAT);
			const endOfMonthString = dateToString(endOfMonth, YEAR_MONTH_DAY_TIME_FORMAT);
			const startOfTodayString = dateToString(startOfToday, YEAR_MONTH_DAY_TIME_FORMAT);

			const response = await TaskService.statusByDate(startOfMonthString, endOfMonthString, startOfTodayString);
			if (response.success) {
				const statusByDateArray = response.success;
				setStatusByDate(statusByDateArray);
				let days: string[] = [];
				statusByDateArray.forEach((item) => {
					const dayOfTheMonth = stringToDayjs(item.dueDate).format(YEAR_MONTH_DAY_FORMAT);
					days.push(dayOfTheMonth);
				});
				setMarkedDates(days);
			} else {
				message.error(intl.formatMessage(translate('commonError')));
			}
		} catch (e) {
			message.error(intl.formatMessage(translate('commonError')));
		}
	};

	const getDateColorScheme = (date: DayjsType) => {
		const formatedDate = date.format(YEAR_MONTH_DAY_FORMAT);
		const dotList: Record<string, string>[] = [];

		if (markedDates?.includes(formatedDate)) {
			const dayStatus = statusByDate?.find(
				(item) => stringToDayjs(item.dueDate).format(YEAR_MONTH_DAY_FORMAT) === formatedDate
			);

			if (dayStatus?.status) {
				dayStatus?.status.forEach((status) => {
					const id = `${date.toString()} ${status}`;
					switch (status) {
						case 'UNASSIGNED':
							dotList.push({ color: COLOR_STATUS_MAP.UNASSIGNED, id });
							break;
						case 'UNCOMPLETED':
							dotList.push({ color: COLOR_STATUS_MAP.UNCOMPLETED, id });
							break;
						default:
							break;
					}
				});
			}
		}

		return dotList;
	};

	const onSelect = (newValue: DayjsType) => {
		setValue(newValue);
	};

	const dateCellRender = (value: DayjsType) => {
		const dots = getDateColorScheme(value);
		return (
			<ul className="events">
				{dots.map((item) => (
					<li key={item.id}>
						<Badge color={item.color} />
					</li>
				))}
			</ul>
		);
	};

	const onChange = (date: DayjsType) => {
		const monthChanges = date.month() !== value.month();

		if (monthChanges || !value) {
			fetchCalendarData(date);
			setMarkedDates([]);
		}
		setValue(date);
	};

	useEffect(() => {
		setValue(getCurrentDate());
		fetchCalendarData(getCurrentDate());
	}, []);

	return (
		<div className="task-calendar overview-card">
			<Row className="overview-title">
				<FormattedMessage {...translate('calendar')} />
			</Row>
			<Row className="">
				<Col xs={24} sm={24} md={24} lg={14} xl={14}>
					<Calendar
						mode="month"
						dateCellRender={dateCellRender}
						fullscreen={false}
						onSelect={onSelect}
						value={value}
						onChange={onChange}
					/>
				</Col>
				<Col xs={24} sm={24} md={24} lg={10} xl={10}>
					<CalendarList selectedDate={value.format(DAY_MONTH_YEAR_FORMAT)} />
				</Col>
			</Row>
		</div>
	);
};

export default TasksCalendar;
