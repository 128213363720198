export const TASK_OVERFLOW = 74;
export const ROUTE_USER_OVERFLOW = 53;
export const ROUTE_USER_TASK_OVERFLOW = 231;

export enum TASK_STATUS {
	DONE = 'DONE',
	SKIPPED = 'SKIPPED',
	TO_DO = 'TO_DO', // Assigned
	UNASSIGNED = 'UNASSIGNED',
	UNCOMPLETED = 'UNCOMPLETED',
}

export const COLOR_STATUS_MAP: Record<TASK_STATUS, string> = {
	DONE: '#32A960',
	TO_DO: '#329AFF',
	SKIPPED: '#8C8C8C',
	UNASSIGNED: '#FBBC4C',
	UNCOMPLETED: '#FF4D4E',
};

type StatusTags = {
	color: string;
	labelKey: string;
	ribbon: string;
};
export const TASK_STATUS_TAGS: Record<TASK_STATUS, StatusTags> = {
	DONE: { color: 'success', labelKey: 'doneTask', ribbon: COLOR_STATUS_MAP.DONE },
	TO_DO: { color: 'processing', labelKey: 'assigned', ribbon: COLOR_STATUS_MAP.TO_DO },
	SKIPPED: { color: 'default', labelKey: 'skipped', ribbon: COLOR_STATUS_MAP.SKIPPED },
	UNASSIGNED: { color: 'warning', labelKey: 'unassigned', ribbon: COLOR_STATUS_MAP.UNASSIGNED },
	UNCOMPLETED: { color: 'error', labelKey: 'uncompletedTasks', ribbon: COLOR_STATUS_MAP.UNCOMPLETED },
};

export const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
export const DAY_MONTH_YEAR_FORMAT = 'DD-MM-YYYY';
export const YEAR_MONTH_DAY_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
export const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

// Map limits to avoid it from navigating into blank tiles
export const MAP_BOUNDS_EDGES = {
	north: 85,
	south: -85,
	east: 180,
	west: -180,
};

export const MAP_RESTRICTED_ZOOM = {
	minZoom: 3,
	maxZoom: 20,
};
