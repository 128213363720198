import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import qs from 'qs';
import unitSystem from '../../features/unitSystems';
import { TASK_STATUS } from '../constants';
import { Task } from '../entities/tasks.entity';
import {
	ApiResult,
	AssignMassive,
	EntityFilter,
	Filter,
	OutdatedTaskAndTotal,
	QueueAssignedOptimizedResponse,
	Result,
	Service,
	StatusByDate,
	TaskStatusCount,
} from '../interfaces';
import { OptimizationQueueValues } from '../pages/TasksScheduler/components/taskAndTeamPanel/OptimizationQueueView';
import {
	AssignMassiveOptimizedType,
	AssignMassiveType,
	CreateTask,
	GeneratedTasksResponseWithErrors,
	OptimizeRouteQueueStatusTypes,
	TaskFileImportType,
} from '../types/tasks.types';

const BASE_URL = `/v2/tasks`;

type TaskServiceType = Service<Task> & {
	assignMassive: (values: AssignMassiveType) => Promise<Result<AssignMassive>>;
	assignMassiveOptimized: (values: AssignMassiveOptimizedType) => Promise<QueueAssignedOptimizedResponse>;
	generateTasksMassive: (values: FormData) => Promise<GeneratedTasksResponseWithErrors>;
	getFailedTasksFile: (values: any, importType: TaskFileImportType) => Promise<void>;
	getStatusCount: (filter: EntityFilter<Task>, today: string) => Promise<Result<TaskStatusCount>>;
	statusByDate: (startDate: string, endDate: string, today: string) => Promise<Result<StatusByDate[]>>;
	outdatedTasks: (today: string, status: TASK_STATUS) => Promise<Result<OutdatedTaskAndTotal>>;
	reorder: (data: Task[]) => Promise<Result<{ tasksChanged: number }>>;
	deleteByBatch: (filter?: EntityFilter<Task>) => Promise<Result<Task[]>>;
	updateByBatch: (data: Partial<Task>, filter?: EntityFilter<Task>) => Promise<Result<{ affected: number }>>;
	requestOptimizedRouteStatus: (queueId: number) => Promise<OptimizeRouteQueueStatusTypes>;
	getOptimizationQueueCount: (clientId: number) => Promise<number>;
	getOptimizationQueuePage: (clientId: number, page: number, pageSize: number) => Promise<OptimizationQueueValues[]>;
};

const get = async ({ skip, take, filter = {} }: Filter<Task>): Promise<ApiResult<Task[]>> => {
	const request = { skip, take, filter: JSON.stringify(filter) };
	const response: AxiosResponse<ApiResult<Task[]>> = await axios.get(`${BASE_URL}?${qs.stringify(request)}`);

	return response.data;
};

const remove = async (id: number): Promise<void> => await axios.delete(`${BASE_URL}/${id}`);

const deleteByBatch = async (filter?: EntityFilter<Task>): Promise<Result<Task[]>> => {
	const request = await axios.delete(`${BASE_URL}`, { data: { filter } });
	return request.data;
};

const updateByBatch = async (data: Partial<Task>, filter?: EntityFilter<Task>) => {
	const body = {
		filter: filter,
		task: data,
	};
	const request = await axios.patch(`${BASE_URL}`, body);
	return request.data;
};

const create = async (task: CreateTask): Promise<Task> => {
	const response = await axios.post(BASE_URL, task);

	return response.data;
};

const update = async (update: Partial<Task>): Promise<Task> => {
	const { id, ...body } = update;
	const response = await axios.patch<Task>(`${BASE_URL}/${id}`, body);

	return response.data;
};

const getStatusCount = async (filter: EntityFilter<Task>, today: string): Promise<Result<TaskStatusCount>> => {
	const request = { filter: JSON.stringify(filter) };
	const response = await axios.get(`${BASE_URL}/status-count?${qs.stringify(request)}&today=${today}`);
	return response.data;
};

const statusByDate = async (startDate: string, endDate: string, today: string): Promise<Result<StatusByDate[]>> => {
	const response = await axios.get(
		`${BASE_URL}/status-by-date?start-date=${startDate}&end-date=${endDate}&today=${today}`
	);
	return response.data;
};

const outdatedTasks = async (today: string, status: TASK_STATUS): Promise<Result<OutdatedTaskAndTotal>> => {
	const response = await axios.get(`${BASE_URL}/outdated-task?today=${today}&status=${status}`);
	return response.data;
};

const assignMassive = async ({
	routeUserId,
	taskIds,
	status,
	order = -1,
}: AssignMassiveType): Promise<Result<AssignMassive>> => {
	const body = {
		routeUserId,
		taskIds,
		order,
		status,
	};
	const response = await axios.post(`${BASE_URL}/assign-massive`, body);

	return response.data;
};

const assignMassiveOptimized = async ({
	userIds,
	taskIds,
	clientId,
	optimizationType,
}: AssignMassiveOptimizedType): Promise<QueueAssignedOptimizedResponse> => {
	const body = {
		userIds,
		taskIds,
		clientId,
		optimizationType,
	};
	const response = await axios.post<QueueAssignedOptimizedResponse>(`${BASE_URL}/assign-optimized`, body);
	return response.data;
};

const requestOptimizedRouteStatus = async (queueId: number): Promise<OptimizeRouteQueueStatusTypes> => {
	const response = await axios.get(`${BASE_URL}/route-optimization-status/${queueId}`);
	return response.data;
};

const generateTasksMassive = async (data: FormData): Promise<GeneratedTasksResponseWithErrors> => {
	const response = await axios.post<GeneratedTasksResponseWithErrors>(`${BASE_URL}/import-tasks`, data, {
		headers: {
			'content-type': 'multipart/form-data',
		},
	});
	return response.data;
};

const reorder = async (data: Task[]) => {
	const response = await axios.post(`${BASE_URL}/reorder`, data);

	return response.data;
};

const getFailedTasksFile = async (data: Partial<Task>[], importType: TaskFileImportType = 'ADDRESS'): Promise<void> => {
	const body = { failedTasks: data, importType };
	const response = await axios.post(`${BASE_URL}/failed-tasks`, body, {
		responseType: 'blob',
	});
	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `failed_tasks-${moment().format(unitSystem.getDateTimeFormat())}.xlsx`);
	document.body.appendChild(link);
	link.click();
};

const getOptimizationQueueCount = async (clientId: number): Promise<number> => {
	const response = await axios.get(`${BASE_URL}/optimization-queue-count/${clientId}`);
	return response.data;
};

const getOptimizationQueuePage = async (
	clientId: number,
	page: number = 1,
	pageSize: number = 10
): Promise<OptimizationQueueValues[]> => {
	const response = await axios.get(`${BASE_URL}/optimization-queue/${clientId}?page=${page}&pageSize=${pageSize}`);
	return response.data;
};

const TaskService: TaskServiceType = {
	get,
	create,
	update,
	delete: remove,
	getStatusCount,
	assignMassive,
	assignMassiveOptimized,
	generateTasksMassive,
	getFailedTasksFile,
	statusByDate,
	outdatedTasks,
	reorder,
	deleteByBatch,
	updateByBatch,
	requestOptimizedRouteStatus,
	getOptimizationQueueCount,
	getOptimizationQueuePage,
};

export default TaskService;
