export const FETCH_GROUPS = 'FETCH_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const FETCH_GROUP_DEVICES = 'FETCH_GROUP_DEVICES';
export const EDIT_GROUP_DEVICES = 'EDIT_GROUP_DEVICES';
export const FETCH_GROUP_ACCOUNTS = 'FETCH_GROUP_ACCOUNTS';
export const EDIT_GROUP_ACCOUNTS = 'EDIT_GROUP_ACCOUNTS';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const UN_SHARE_GROUP = 'UN_SHARE_GROUP';
