import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@smovilgps/optimus-intl';
import { Alert, Button, Col, Form, Row, Space } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { AUTHORIZED_SCHEDULE_ERRORS } from '@/constants/global';
import AuthorizedSchedulePicker from '@/features/alertConfiguration/components/AuthorizedSchedulePicker';
import { getNowInMilliseconds, toDayjs, ValidateIntervalDates, validateIntervalDates } from '@/utilities/dateUtils';

export type AuthorizedSchedule = {
	from: Date | string;
	to: Date | string;
	days: number[];
};
type Props = {
	scheduleList?: AuthorizedSchedule[];
	enabled: boolean;
};
export const scheduleTemplate: AuthorizedSchedule = {
	from: '08:00',
	to: '17:00',
	days: [1, 2, 3, 4, 5],
};

const AuthorizeScheduleConfig = ({ scheduleList = [scheduleTemplate], enabled }: Props) => {
	const intl = useIntl();
	const form = Form.useFormInstance();
	const [schedules, setSchedules] = useState<AuthorizedSchedule[]>(scheduleList);
	const [validationError, setValidationError] = useState<string>();

	useEffect(() => {
		let schedules = enabled ? scheduleList : [scheduleTemplate];
		form.setFieldValue(['extraConfig', 'schedule'], schedules);
		setSchedules(schedules);
	}, [enabled]);

	const onChange = (scheduleIndex: number, updatedSchedule: AuthorizedSchedule) => {
		const schedule = schedules.map((schedule, index) => (index === scheduleIndex ? updatedSchedule : schedule));
		form.setFieldValue(['extraConfig', 'schedule'], schedule);
		setSchedules(schedule);
	};

	const addScheduleTemplate = () => {
		const schedule = [...schedules, scheduleTemplate];
		form.setFieldValue(['extraConfig', 'schedule'], schedule);
		setSchedules(schedule);
	};

	const removeSchedule = (scheduleIndex: number) => {
		const schedule = schedules.filter((_, index) => index !== scheduleIndex);
		form.setFieldValue(['extraConfig', 'schedule'], schedule);
		setSchedules(schedule);
	};

	const groupByScheduleDay = (intervals: AuthorizedSchedule[]): Record<number, ValidateIntervalDates[]> =>
		intervals.reduce((current, timeInterval) => {
			if (!timeInterval.days.length) {
				current[-1] = [];
			} else {
				timeInterval.days.forEach((day: number) => {
					if (!current[day]) {
						current[day] = [];
					}
					current[day].push(timeInterval);
				});
			}
			return current;
		}, {} as Record<number, ValidateIntervalDates[]>);

	const validator = async (_: RuleObject, value: AuthorizedSchedule[] = []) => {
		if (!enabled) {
			return Promise.resolve();
		}
		for (let index = 0; index < value.length; index++) {
			const { from, to } = value[index];
			if (toDayjs(from, 'HH:mm').isSame(toDayjs(to, 'HH:mm'))) {
				setValidationError(AUTHORIZED_SCHEDULE_ERRORS.SAME_FROM_TO_ERROR);
				return Promise.reject();
			}
		}

		const dayIntervals = groupByScheduleDay(value);
		if (!Object.keys(dayIntervals).length) {
			setValidationError(AUTHORIZED_SCHEDULE_ERRORS.EMPTY_ERROR);
			return Promise.reject();
		}
		for (let index = 0; index < Object.keys(dayIntervals).length; index++) {
			const key = +Object.keys(dayIntervals)[index];
			const daySchedule = dayIntervals[key];
			if (!daySchedule.length) {
				setValidationError(AUTHORIZED_SCHEDULE_ERRORS.EMPTY_DAY_ERROR);
				return Promise.reject();
			}
			const timeIntervals = daySchedule.map(({ from, to }) => ({
				from,
				to,
			}));

			if (!validateIntervalDates(timeIntervals)) {
				setValidationError(AUTHORIZED_SCHEDULE_ERRORS.OVERLAP_ERROR);
				return Promise.reject();
			}
		}
		setValidationError(undefined);
		return Promise.resolve();
	};

	const schedulers = schedules.map(({ from, to, days }, index) => (
		<AuthorizedSchedulePicker
			key={`${index}-${getNowInMilliseconds()}`}
			from={from}
			to={to}
			days={days}
			onScheduleChange={(schedule: AuthorizedSchedule) => onChange(index, schedule)}
			onDelete={() => removeSchedule(index)}
			disableDelete={schedules.length === 1}
			disabled={!enabled}
		/>
	));

	const ValidationError = validationError && (
		<Alert message={intl.formatMessage(translate(validationError))} type="error" showIcon />
	);

	return (
		<Row className="authorize-schedule-config">
			<Col span={24} className="section-title">
				<FormattedMessage {...translate('authorizeScheduleConfigTitle')} />
			</Col>
			<Col span={24}>
				<Space direction="vertical" size={17} className="authorize-schedule-config-cards">
					{schedulers}
				</Space>
			</Col>
			<Col span={24} className="authorize-schedule-config-error">
				{ValidationError}
			</Col>
			<Col span={24} className="authorize-schedule-config-add">
				<Button key="add" block type="dashed" onClick={addScheduleTemplate} disabled={!enabled}>
					<FormattedMessage {...translate('addAuthorizedSchedule')} />
				</Button>
			</Col>
			<Form.Item name={['extraConfig', 'schedule']} hidden rules={[{ validator }]}>
				<input hidden />
			</Form.Item>
			<Form.Item name={['extraConfig', 'otherProps']} hidden>
				<input hidden />
			</Form.Item>
		</Row>
	);
};

export default AuthorizeScheduleConfig;
