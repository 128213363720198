import { configureStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk, { ThunkDispatch } from 'redux-thunk';
import createRootReducer from '../reducers';

const reducers = createRootReducer();

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['temp', 'reports', 'providersPage', 'connection'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: [thunk],
});

export const persitor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, null, AnyAction>;
