import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@smovilgps/optimus-intl';
import { Badge, Col, message, Row, Spin } from 'antd';
import { dateToString, formatDate, getUtcStartAndEndOfDate } from '../../../../utilities/dateUtils';
import { COLOR_STATUS_MAP, DAY_MONTH_YEAR_FORMAT, YEAR_MONTH_DAY_TIME_FORMAT } from '../../../constants';
import { Task } from '../../../entities/tasks.entity';
import { EntityFilter, TaskStatusCount } from '../../../interfaces';
import { TaskService } from '../../../services';

type Props = {
	selectedDate: string;
};

const icons: Record<string, JSX.Element> = {
	total: <span className="icon-task" />,
	skipped: <Badge color={COLOR_STATUS_MAP.SKIPPED} />,
	done: <Badge color={COLOR_STATUS_MAP.DONE} />,
	unassigned: <Badge color={COLOR_STATUS_MAP.UNASSIGNED} />,
	uncompleted: <Badge color={COLOR_STATUS_MAP.UNCOMPLETED} />,
	assigned: <Badge color={COLOR_STATUS_MAP.TO_DO} />,
	default: <></>,
};

const CalendarList = ({ selectedDate }: Props) => {
	const intl = useIntl();

	const [loader, setLoader] = useState<boolean>(false);
	const [statusCount, setStatusCount] = useState<TaskStatusCount>();

	const formatSearchFilter = (): EntityFilter<Task> => {
		const { startOfDate: startOfDay, endOfDate: endOfDay } = getUtcStartAndEndOfDate(
			selectedDate,
			DAY_MONTH_YEAR_FORMAT
		);
		return {
			where: [
				{
					dueDate: {
						op: 'between',
						value: { from: startOfDay, to: endOfDay },
					},
				},
			],
		};
	};

	const fetchDateData = async () => {
		try {
			setLoader(true);
			const today = formatDate(DAY_MONTH_YEAR_FORMAT, new Date());
			const { startOfDate: startOfToday } = getUtcStartAndEndOfDate(today, DAY_MONTH_YEAR_FORMAT, 'day');
			const startOfTodayString = dateToString(startOfToday, YEAR_MONTH_DAY_TIME_FORMAT);

			const response = await TaskService.getStatusCount(formatSearchFilter(), startOfTodayString);
			if (response.success) {
				setStatusCount(response.success);
			} else {
				message.error(intl.formatMessage(translate('commonError')));
			}
		} catch (e) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoader(false);
		}
	};

	const ListDetail = useMemo(() => {
		if (statusCount) {
			return Object.entries(statusCount).map(([key, value]) => (
				<Row key={key} justify="space-between" className={`calendar-list ${key === 'total' ? 'total' : ''}`}>
					<Col span={3} className="icon">
						{icons[key] || icons['default']}
					</Col>
					<Col span={17}>
						<FormattedMessage {...translate(key + 'Tasks')} />
					</Col>
					<Col span={2} className="quantity">
						{value}
					</Col>
				</Row>
			));
		} else {
			return <></>;
		}
	}, [statusCount]);

	useEffect(() => {
		fetchDateData();
	}, [selectedDate]);

	return (
		<Spin spinning={loader}>
			<div className="calendar-list-container">{ListDetail}</div>
		</Spin>
	);
};

export default CalendarList;
