export const FETCH_MAP_DEVICES = 'FETCH_MAP_DEVICES';
export const UPDATE_MAP_DEVICES = 'UPDATE_MAP_DEVICES';
export const FETCH_SELECTED_DEVICE = 'FETCH_SELECTED_DEVICE';
export const TOGGLE_REFRESH = 'TOGGLE_REFRESH';
export const TOGGLE_OTHER_UNITS = 'TOGGLE_OTHER_UNITS';
export const INIT_FETCH_MAP_DEVICES = 'INIT_FETCH_MAP_DEVICES';
export const END_FETCH_MAP_DEVICES = 'END_FETCH_MAP_DEVICES';
export const FETCH_DEVICE_COMMANDS = 'FETCH_DEVICE_COMMANDS';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const LAST_SELECTED_DEVICE = 'LAST_SELECTED_DEVICE';
export const LAST_TREE_SELECTED_DEVICES = 'LAST_TREE_SELECTED_DEVICES';
export const LAST_MULTIPLE_MAP_DEVICES = 'LAST_MULTIPLE_MAP_DEVICES';
