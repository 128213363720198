import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_GROUP,
	CLEAN_REDUCER,
	DELETE_GROUP,
	EDIT_GROUP,
	EDIT_GROUP_ACCOUNTS,
	EDIT_GROUP_DEVICES,
	FETCH_GROUP_ACCOUNTS,
	FETCH_GROUP_DEVICES,
	FETCH_GROUPS,
	UN_SHARE_GROUP,
} from './actionTypes';

const initState = {
	groups: [],
	devices: [],
	accounts: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case UN_SHARE_GROUP:
		case EDIT_GROUP_DEVICES:
		case EDIT_GROUP_ACCOUNTS:
		case DELETE_GROUP:
		case EDIT_GROUP:
		case FETCH_GROUPS:
			return {
				...state,
				groups: action.groups,
			};
		case ADD_GROUP:
			return {
				...state,
				groups: [...state.groups, action.group],
			};
		case FETCH_GROUP_DEVICES:
			return {
				...state,
				devices: action.devices,
			};
		case FETCH_GROUP_ACCOUNTS:
			return {
				...state,
				accounts: action.accounts,
			};

		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
